import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  Breadcrumbs,
  TextBox,
  ImageSlider,
  Phone,
  VideoAndText,
  BannerLinks,
  BannerSmall,
  ProjectPagination
} from '../../components'

import JoinTheTeam from '../../assets/bannerlinks/jointheteam.jpg'
import TakeOff from '../../assets/bannerlinks/takeoff.jpg'
import { generateBreadcrumbsLinks } from '../../utils/index'

//import berufsreisevideo from 'https://youtu.be/tLCMzLGALSY'
import BannerImage from '../../assets/header.berufsreise.jpg'
import imag1 from '../../assets/berufsreise.guide.jpg'
import imag2 from '../../assets/berufsreise.alpinwelt.jpg'
import imag3 from '../../assets/berufsreise.tropenwelt.jpg'
import image4 from '../../assets/bannerlinks/bannerbox.wedl.jpg'
import image5 from '../../assets/bannerlinks/dibk.dom.jpg'

const boxes = [
  {
    text: 'Tolle Projekte, sichere Jobs',
    image: JoinTheTeam,
    link: '/bewerbung',
    linkText: 'Bewerbung'
  },
  {
    text: 'Gemeinsames Projekt?',
    image: TakeOff,
    link: '/kontakt',
    linkText: 'Kontakt'
  }
]

const images = [
  {
    image: imag1,
    alt: "Guide"
  },
  {
    image: imag2,
    alt: "Alpen Landschaft"
  },
  {
    image: imag3,
    alt: "Jungle Landschaft"
  }
]

const Berufsreise = () => {
  const links = generateBreadcrumbsLinks('/projekte/berufsreise', 'Berufsreise')
  return (
    <Layout margin={false} color="black">
    <Helmet
      title="Eine Plattform zur Berufsorientierung"
      meta={[
        {
          name: 'description',
          content:
            'Mit berufsreise.at hat holzweg ein Webportal geschaffen, in dem sich vier verschiedene Zielgruppen - Schüler, Lehrer, Eltern und Unternehmer - miteinander austauschen können. '
        },
        {
          name: 'keywords',
          content:
            'berufsreise holzweg, Projekt holzweg, Innsbruck, Webseite holzweg, berufsreise, Schüler berufsreise'
        },
        {
          property: 'og:description',
          content:
            'Mit berufsreise.at hat holzweg ein Webportal geschaffen, in dem sich vier verschiedene Zielgruppen - Schüler, Lehrer, Eltern und Unternehmer - miteinander austauschen können. '
        },
        {
          property: 'og:image',
          content:
            'https://www.holzweg.com/static/berufsreise.guide-f8cd8325285c3f938f1fe1366233653f.jpg'
        }
      ]}
    />
      <BannerSmall image={BannerImage} title={
        <span>berufs&#173;reise.at</span>
        }
      />
      <Breadcrumbs
        links={links}
        backLinkAlias="Übersicht aller aktuellen Projekte"
      />
      <TextBox
        title={
          <div>
            Eine Plattform
            <br />
            zur Berufs&#173;orientierung
          </div>
        }
        text={
          <span>
            <p>
              Mit <b>berufsreise.at</b> hat holzweg ein Webportal geschaffen, in dem sich vier verschiedene Zielgruppen - Schüler, Lehrer, Eltern und Unternehmer - miteinander austauschen können. Ähnlich wie auf einer Social Media Plattform kann ein Profil angelegt, miteinander kommuniziert, Veranstaltungen erstellt und geteilt sowie offene Lehrstellen angeboten werden. Kunde: WIFI Wirtschaftskammer Tirol</p>
          </span>
        }
        buttons={[
          {link: 'https://www.berufsreise.at', text: 'berufsreise.at'},
        ]}
      />
      <Phone
        title="Technische Umsetzung"
        description="Die Basis für die technische Umsetzung der Multiportalseite ist das PHP-Framework Symfony. Die Funktionen der Plattform wurden individuell an die Wünsche unseres Kundens angepasst. Ein weiterer wichtiger Bestandteil der Website ist die Suchfunktion, die über die search engine Apache Solr abgewickelt wird."
        mainDescription="Symfony, Apache Solr, jQuery"
        link="https://www.berufsreise.at"
        bgColor="#FFFFFF"
      />
      <TextBox
        title="Berufsreise als Spiel"
        text={
          <span>
            <p>Ein weiteres Kernstücke der Plattform ist das Berufsreisespiel für SchülerInnen. Das Spiel wird durch <b>Parallaxeffekte und GIF-Animationen</b> zu einer lebendigen und ansprechenden Seite. In den verschiedenen und aufwändig illustrierten Landschaften, der Alpen-, Tropen- und Weltraumlandschaft, verfolgen SchülerInnen ihre persönliche Berufsreise (Illustrator: Christian Opperer).</p>
            <p>Besuchte Veranstaltungen werden mit Symbolen der Landschaft dargestellt und der Weg von einer Veranstaltung zur nächsten wird spielerisch mit Fußstapfen gekennzeichnet. Ein Guide begleitet die SchülerInnen auf ihrem Weg und kann jederzeit per Klick um Rat gefragt werden. Das Spiel erlaubt Punkte auf der Plattform zu sammeln (z.B. durch besuchte Veranstaltungen oder angesehene Berufsbeschreibungen), die zukünftig gegen Prämien und Preise eingetauscht werden können.</p><h3>Hier ein paar Ausschnitte aus den tollen Bilderwelten:</h3>
          </span>
        }
      />
      <ImageSlider images={images} />
      <VideoAndText
        YTvideo="tLCMzLGALSY"
        headline="Videodreh für die Berufsreise bei holzweg"
        text="Simon Mayr, Lehrling bei holzweg GmbH, wurde vom WIFI Tirol im Rahmen des berufsreise.at Projektes interviewed und ein Video zum Lehrberuf der Informationstechnologie mit ihm gedreht. Wir sind natürlich mächtig stolz auf unseren Filmstar!"
      />
      <TextBox titleMods={["center"]}
        title="Weitere Projekte"
        text={false}
      />
      <ProjectPagination
        titleLeft="Wedl Handelshaus"
        imageLeft={image4}
        leftLink="/projekte/wedl"
        titleRight={
          <span>Diözese Innsbruck <br/>Soft Redesign</span>
        }
        imageRight={image5}
        rightLink="/projekte/dioezese"
        titleModsRight={['white']}
      />
      <TextBox
        title="Walk with us on the holzweg"
        text="Sie haben ein technisch spannendes Projekt in Planung? Dann kontaktiere Sie uns zu einem unverbindlichen Beratungsgespräch oder verwenden Sie unsere Call-Back Funktion unter dem Kontakt. Wenn der holzweg nach sicherem Job und tollen Projekten klingt, dann ab zur Bewerbung!"
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}

export default Berufsreise
